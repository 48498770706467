'use client'

import React, { FC } from 'react'
import logger from '@ignition/library/lib/logger'
import { PageTypeProps } from '../../types/site.interface'
import { Button } from '../atoms/button'
import ImageElement from '../elements/image'

const NotFoundPage: FC<PageTypeProps> = ({ page }) => {
  if (!page) {
    logger.error('"Error Page (404)" is not set in Sanity, or the page data is missing')
  }
  return (
    <div className="relative isolate min-h-full">
      <ImageElement
        className="absolute inset-0 -z-10 h-full w-full"
        objectPosition={'object-center-bottom'}
        image={page?.image}
        fill
        priority={true}
      />
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8 ">
        <p className="text-xl font-semibold leading-8 text-white drop-shadow-depth">404</p>
        <h1 className="mt-4 text-3xl font-semibold tracking-tight text-white sm:text-5xl drop-shadow-depth">
          {page?.title}
        </h1>
        <p className="mt-4 text-lg text-white sm:mt-6 shadow-2xl drop-shadow-depth">{page?.summary}</p>
        <div className="mt-10 flex justify-center">
          <Button className="font-semibold leading-7 text-white" href="/">
            <span aria-hidden="true">&larr;</span>
            {'  '} Back to home
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
